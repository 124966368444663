import { Configuration } from "./generated";
import { fetchAuthSession } from "aws-amplify/auth";

export const FAY_API_URL = process.env.REACT_APP_FAY_API_BASE_PATH;

const configuration = new Configuration({
  basePath: FAY_API_URL,
  accessToken: async () => {
    const session = await fetchAuthSession();
    const { idToken } = session.tokens ?? {};
    const rawToken = idToken?.toString() ?? "";
    const tokenPrefix = {
      provider: "provider",
      client: "patient",
    }[process.env.REACT_APP_FAY_APP ?? ""];

    if (!tokenPrefix) {
      throw new Error("Invalid app configuration");
    }

    return `${tokenPrefix}::::${rawToken}`;
  },
  baseOptions: {
    headers:
      process.env.NODE_ENV === "development"
        ? {
            "ngrok-skip-browser-warning": `TRUE`,
          }
        : {},
  },
});

export default configuration;
